import React from 'react';
import Banner from "./banner";
import Layout from "../layout/layout";
import Seo from "../layout/seo";
import Breadcrumbs from "../components/breadcrumbs";
import Servicelisting from "./servicelisting";
import Featured from "../home/featured";
import Partner from "../home/partner";

function Index(props) {
    return (
        <>
            <Layout>
                <Seo title='TekRevol Services' description='TekRevol Services'/>
                <Breadcrumbs link1="TekRevol" link2="Services"/>
                <Banner title="Services"/>
                <Servicelisting/>
                <Featured/>
                <Partner/>
            </Layout>
        </>
    );
}

export default Index;